<app-header></app-header>
<div class="eps-container">
    <div class="row text-center">
        <h4 class="mt-3">CLASS Observation Report Text Reconciliation</h4>
    </div>
    <div class="row" *ngIf="!isLoading">
        <div class="col-12">
            <div class="card mt-3">
                <div class="card-body">
                    <h5 class="card-title">{{ reconciliationData.school.name }} ({{ reconciliationData.school.key }}
                        )</h5>
                    <div class="row">
                        <div class="col-6 d-flex align-items-center">
                            Dimension: {{ selectedDimension.name }}
                        </div>
                        <div class="col-6">
                            <div class="alert alert-info" role="alert">
                                {{ infoMessage() }}
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th colspan="2" class="text-center">Low Range</th>
                                    <th colspan="3" class="text-center">Middle Range</th>
                                    <th colspan="2" class="text-center">High Range</th>
                                    <th colspan="2">&nbsp;</th>
                                </tr>
                                <tr>
                                    <th scope="col">Grade Band</th>
                                    <th scope="col" class="text-center">1</th>
                                    <th scope="col" class="text-center">2</th>
                                    <th scope="col" class="text-center">3</th>
                                    <th scope="col" class="text-center">4</th>
                                    <th scope="col" class="text-center">5</th>
                                    <th scope="col" class="text-center">6</th>
                                    <th scope="col" class="text-center">7</th>
                                    <th scope="col" class="text-center">n</th>
                                    <th scope="col" class="text-center">Average</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let row of selectedDimension.survey_response_data">
                                    <td>{{ row.grade_name }}</td>
                                    <td class="text-center">{{ row.one_rating_count }}</td>
                                    <td class="text-center">{{ row.two_rating_count }}</td>
                                    <td class="text-center">{{ row.three_rating_count }}</td>
                                    <td class="text-center">{{ row.four_rating_count }}</td>
                                    <td class="text-center">{{ row.five_rating_count }}</td>
                                    <td class="text-center">{{ row.six_rating_count }}</td>
                                    <td class="text-center">{{ row.seven_rating_count }}</td>
                                    <td class="text-center">{{ row.total_count }}</td>
                                    <td class="text-center">{{ row.average_rating | number: '1.1-1' }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label for="grade" class="form-label">Step 1: Choose a grade</label>
                            <select class="form-select" id="grade" aria-label="Default select example"
                                    [formControl]="grade">
                                <option *ngFor="let g of grades" [value]="g.id">{{ g.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label for="grade" class="form-label">Step 2: Choose an indicator</label>
                            <select class="form-select" id="indicator" aria-label="Default select example"
                                    [formControl]="indicator">
                                <option *ngFor="let i of indicators" [value]="i.id">{{ i.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-3">
                            <label for="grade" class="form-label">Step 3: Choose a behavioral marker</label>
                            <select class="form-select" id="behavioralMarker" aria-label="Default select example"
                                    [formControl]="behavioralMarker">
                                <option *ngFor="let bm of behavioralMarkers" [value]="bm.id">{{ bm.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3">
                            <textarea class="form-control" id="observation" rows="6"
                                      [formControl]="observation"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
                            <button type="button" class="btn btn-primary ml-2" (click)="saveObservation()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isLoading">
        <app-animated-loader></app-animated-loader>
    </div>
</div>