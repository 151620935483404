import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {PostCampaign, Campaign} from "../_models";

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  remoteAPI = environment.remoteAPI;

  constructor(private http: HttpClient) { }

  public verifyCampaign(campaignId: number): Observable<any> {
    return this.http
        .post(`${this.remoteAPI}/campaigns/${campaignId}/verify`, {})
        .pipe(catchError(error => this.handleError(error)));
  }

  public postCampaign(campaignData: PostCampaign): Observable<Campaign> {
    return this.http
        .post<Campaign>(`${this.remoteAPI}/campaigns`, campaignData)
        .pipe(catchError(error => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      this.log(`An error occurred: ${error.error}`);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      this.log(
          `Backend returned code ${error.message}, ` + `body was: ${error.status}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }

  private log(message: string) {
    console.log(`CampaignService: ${message}`);
  }
}
