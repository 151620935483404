import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {
    CLASSObservationsPostAPI,
    CLASSObservationsReconciliation,
    DimensionAPI
} from "../_models";
import {catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ClassObservationsService {

    remoteAPI = environment.remoteAPI;

    constructor(private http: HttpClient) {
    }

    public getCLASSObservations(schoolId: number): Observable<DimensionAPI[]> {
        let params = new HttpParams();
        params = params.append('school_id', schoolId.toString());
        return this.http
            .get<DimensionAPI[]>(`${this.remoteAPI}/class_observations/`, {params: params})
            .pipe(catchError(error => this.handleError(error)));
    }

    public createCLASSObservations(data: CLASSObservationsPostAPI): Observable<string> {
        return this.http
            .post<string>(`${this.remoteAPI}/class_observations/`, data)
            .pipe(catchError(error => this.handleError(error)));
    }

    public deleteCLASSObservation(observationId: number): Observable<any> {
        return this.http
            .delete(`${this.remoteAPI}/class_observations/${observationId}`)
            .pipe(catchError((error) => this.handleError(error)));
    }

    public getCLASSObservationReconciliation(schoolId: number, dimensionID: number, reconciliationID: number = null): Observable<CLASSObservationsReconciliation> {
        let params = new HttpParams();
        params = params.append('school_id', schoolId.toString());
        params = params.append('dimension_id', dimensionID.toString());
        if (reconciliationID) {
            params = params.append('reconciliation_id', reconciliationID.toString());
        }
        return this.http
            .get<CLASSObservationsReconciliation>(`${this.remoteAPI}/class_observations/reconciliation`, {params: params})
            .pipe(catchError((error) => this.handleError(error)));
    }

    public putCLASSObservationReconciliation(observationId: number, data: CLASSObservationsPostAPI): Observable<any> {
        return this.http
            .put(`${this.remoteAPI}/class_observations/${observationId}`, data)
            .pipe(catchError((error) => this.handleError(error)));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.log(`An error occurred: ${error.error}`);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            this.log(
                `Backend returned code ${error.message}, ` + `body was: ${error.status}`
            );
        }
        // return an observable with a user-facing error message
        return throwError(error.error);
    }

    private log(message: string) {
        console.log(`DataService: ${message}`);
    }
}
