import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatParagraph'
})
export class FormatParagraphPipe implements PipeTransform {

    transform(value: string): string {
        return value.replace(/\n/g, '<br/>');
    }

}
