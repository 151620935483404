import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";

import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {SurveyModule} from "survey-angular-ui";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {JwtInterceptor} from "./_helpers";

import {fas} from "@fortawesome/free-solid-svg-icons";
import {DataTablesModule} from "angular-datatables";
import {FileUploadModule} from "ng2-file-upload";
import {NgxSortableModule} from "ngx-sortable";
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {HeaderComponent} from "./_components/header/header.component";
import {AuthGuard, CanDeactivateGuard, HasPermDirective} from "./_guards";
import {Interceptor} from "./_helpers";
import {LoginComponent} from "./login/login.component";
import {
    ProfileComponent,
    ProfileSkeletonComponent,
} from "./profile/profile.component";

import {
    FaIconLibrary,
    FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import {SurveyCreatorModule} from "survey-creator-angular";
import {
    AddCustomFieldModalComponent,
    AddDocumentsModalComponent,
    AddUserModalComponent,
    AnimatedLoaderComponent,
    ChangePasswordModalComponent,
    CheckboxCustomFieldComponent,
    CustomFieldsCreatorComponent,
    DateCustomFieldComponent,
    DialogComponent,
    LandingPageComponent,
    MetabaseDashboardComponent,
    NumberCustomFieldComponent,
    RadioButtonCustomFieldComponent,
    RankListCustomFieldComponent,
    RenderCustomFieldsComponent,
    SelectCustomFieldComponent,
    StatusBadgeComponent,
    TextCustomFieldComponent,
    TextareaCustomFieldComponent,
    UploadDocumentsModalComponent,
} from "./_components";
import {ActivityListItemComponent} from "./_components/activity-list-item/activity-list-item.component";
import {DocumentListItemComponent} from "./_components/document-list-item/document-list-item.component";
import {EditTextComponent} from "./_components/edit-textarea/edit-textarea.component";
import {EpsAvatarComponent} from "./_components/eps-avatar/eps-avatar.component";
import {FormListItemComponent} from "./_components/form-list-item/form-list-item.component";
import {HistoryComponent} from "./_components/history/history.component";
import {NoPermissionComponent} from "./_components";
import {NotesComponent} from "./_components/notes/notes.component";
import {ResetPasswordComponent} from "./_components/reset-password/reset-password.component";
import {ActivityComponent} from "./activities/activity/activity.component";
import {
    EditActivityDescriptionComponent
} from "./activities/activity/edit-activity-description/edit-activity-description.component";
import {EditActivityNameComponent} from "./activities/activity/edit-activity-name/edit-activity-name.component";
import {LeftPaneComponent} from "./activities/activity/left-pane/left-pane.component";
import {RightPaneComponent} from "./activities/activity/right-pane/right-pane.component";
import {
    ActivityDefinitionFormComponent
} from "./activities/activity_definitions/activity-definition-form/activity-definition-form.component";
import {NewActivityModalComponent} from "./activities/new-activity-modal/new-activity-modal.component";
import {
    DashboardComponent,
    DashboardSkeletonComponent,
    ProjectComponent,
    RolesComponent,
    UsersComponent,
    UsersSkeletonComponent,
} from "./admin";
import {DefaultDashboardComponent} from "./admin/default-dashboard/default-dashboard.component";
import {CampaignVerificationComponent} from "./campaigns/campaign-verification/campaign-verification.component";
import {
    CreateCampaignSuccessModalComponent
} from "./campaigns/create-campaign-success-modal/create-campaign-success-modal.component";
import {NewCampaignModalComponent} from "./campaigns/new-campaign-modal/new-campaign-modal.component";
import {
    CaseDefinitionsComponent,
    CasesComponent,
    EditCaseComponent,
    NewCaseComponent,
    NewCaseDefinitionComponent,
} from "./case";
import {
    ListSurveySkeletonComponent,
    ListSurveysComponent,
    SurveyComponent,
    SurveyCreatorComponent,
    SurveyResponseComponent,
    ViewSurveyComponent,
} from "./survey";
import {ActiveTabDirective} from './active-tab.directive';
import {IlnaDashboardComponent} from './_components';
import {
    DataReportingDashboardsComponent
} from './_components/data-reporting-dashboards/data-reporting-dashboards.component';
import {
    ClassObservationReconciliationComponent
} from './class_observations/class-observation-reconciliation/class-observation-reconciliation.component';
import {SummaryPageComponent} from './class_observations/summary-page/summary-page.component';
import {FormatParagraphPipe} from './shared/pipes/format-paragraph.pipe';

@NgModule({
    declarations: [
        AppComponent,
        SurveyComponent,
        SurveyCreatorComponent,
        ListSurveysComponent,
        ListSurveySkeletonComponent,
        ViewSurveyComponent,
        HeaderComponent,
        AnimatedLoaderComponent,
        LoginComponent,
        DashboardComponent,
        DashboardSkeletonComponent,
        AddUserModalComponent,
        AddCustomFieldModalComponent,
        ProfileComponent,
        ProfileSkeletonComponent,
        ChangePasswordModalComponent,
        SurveyResponseComponent,
        DialogComponent,
        CasesComponent,
        AddDocumentsModalComponent,
        CaseDefinitionsComponent,
        NewCaseComponent,
        EditCaseComponent,
        NewCaseDefinitionComponent,
        UploadDocumentsModalComponent,
        StatusBadgeComponent,
        CustomFieldsCreatorComponent,
        RenderCustomFieldsComponent,
        RolesComponent,
        ProjectComponent,
        UsersComponent,
        UsersSkeletonComponent,
        HasPermDirective,
        TextCustomFieldComponent,
        NumberCustomFieldComponent,
        DateCustomFieldComponent,
        TextareaCustomFieldComponent,
        SelectCustomFieldComponent,
        CheckboxCustomFieldComponent,
        RadioButtonCustomFieldComponent,
        RankListCustomFieldComponent,
        DefaultDashboardComponent,
        LandingPageComponent,
        MetabaseDashboardComponent,
        NoPermissionComponent,
        ResetPasswordComponent,
        ActivityComponent,
        LeftPaneComponent,
        RightPaneComponent,
        EpsAvatarComponent,
        EditActivityNameComponent,
        EditActivityDescriptionComponent,
        NotesComponent,
        ActivityDefinitionFormComponent,
        NewActivityModalComponent,
        HistoryComponent,
        NewCampaignModalComponent,
        CampaignVerificationComponent,
        CreateCampaignSuccessModalComponent,
        EditTextComponent,
        ActivityListItemComponent,
        DocumentListItemComponent,
        FormListItemComponent,
        ActiveTabDirective,
        IlnaDashboardComponent,
        DataReportingDashboardsComponent,
        ClassObservationReconciliationComponent,
        SummaryPageComponent,
        FormatParagraphPipe,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        SurveyModule,
        SurveyCreatorModule,
        ReactiveFormsModule,
        FileUploadModule,
        NgxSortableModule,
        ToastrModule.forRoot({
            timeOut: 50000,
            positionClass: "toast-top-right",
            preventDuplicates: true,
        }),
        BrowserAnimationsModule,
        FontAwesomeModule,
        DataTablesModule,
        NgxSkeletonLoaderModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true},
        CanDeactivateGuard,
        AuthGuard,
    ],
    exports: [HasPermDirective],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private library: FaIconLibrary) {
        library.addIconPacks(fas);
    }
}
