import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CampaignService } from "../../_services/campaign.service";
import { GeneralService } from "../../_services";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "../../_models";
import { first } from "rxjs/operators";

@Component({
  selector: "app-campaign-verification",
  templateUrl: "./campaign-verification.component.html",
  styleUrls: ["./campaign-verification.component.scss"],
})
export class CampaignVerificationComponent implements OnInit {
  campaignId: number;
  token: string;
  surveyUrl: string;
  schoolConformedSurveyUrl: string;
  schoolNotConfirmedSurveyUrl: string;
  isLoading = true;
  gotCampaignId = false;
  gotToken = false;
  wasSuccess = false;
  wasFailure = false;
  errorMessage: string;
  schoolName: string;
  gotSchool = false;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private loggedIn = new BehaviorSubject<boolean>(this.tokenAvailable());

  constructor(
    public route: ActivatedRoute,
    private campaignService: CampaignService,
    private router: Router,
    private generalService: GeneralService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.campaignId = params["id"];
      this.gotCampaignId = true;
      this.verifyCampaign();
    });
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];
      this.gotToken = true;
      this.verifyCampaign();
    });
  }

  verifyCampaign() {
    if (this.gotCampaignId && this.gotToken) {
      localStorage.setItem("token", JSON.stringify(this.token));
      this.campaignService
        .verifyCampaign(this.campaignId)
        .pipe(first())
        .subscribe(
          (response) => {
            // console.log(JSON.stringify(response));
            localStorage.setItem(
              "token",
              JSON.stringify(response["access_token"])
            );
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response["profile"])
            );
            this.currentUserSubject.next(response["profile"]);
            this.loggedIn.next(true);
            this.getAppLookupData();
            this.surveyUrl = response["survey_url"];
            this.schoolConformedSurveyUrl = `${response["survey_url"]}&school_confirmed=true`;
            this.schoolNotConfirmedSurveyUrl = `${response["survey_url"]}&school_confirmed=false`;
            this.gotSchool = response["school_id"];
            this.schoolName = response["school_name"];
            this.isLoading = false;
            this.wasSuccess = true;
            this.wasFailure = false;
            // this.router.navigate(['/form', response['survey_id']]);
            // window.location.href = response["survey_url"];
          },
          (error) => {
            this.errorMessage = error.message;
            this.isLoading = false;
            this.wasSuccess = false;
            this.wasFailure = true;
          }
        );
    }
  }

  private tokenAvailable(): boolean {
    return !!localStorage.getItem("token");
  }

  private getAppLookupData(): void {
    this.generalService.getLookupsData().subscribe((data) => {
      localStorage.setItem("lookupData", JSON.stringify(data));
    });
  }
}
