import {Component, OnInit} from '@angular/core';
import {NgbAccordionModule, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CaseService, ClassObservationsService} from "../../_services";
import {CLASSObservationsAPI, DialogOptions, DimensionAPI, School} from "../../_models";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogComponent} from "../../_components";
import {Utils} from "../../_helpers";
import {FormControl} from "@angular/forms";

@Component({
    selector: 'app-summary-page',
    templateUrl: './summary-page.component.html',
    styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent implements OnInit {
    schoolSelect = new FormControl('');
    schools: School[] = [];
    isLoading = true;
    schoolSelected = false;
    classObservations: DimensionAPI[];
    primaryModalOptions: NgbModalOptions = {
        backdrop: "static",
    };

    constructor(
        private classObservationsService: ClassObservationsService,
        private caseService: CaseService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private utils: Utils,) {
        this.schoolSelect.valueChanges.subscribe(value => {
            this.getCLASSObservations(+this.schoolSelect.value);
        });
    }

    ngOnInit(): void {

        this.route.queryParams.subscribe(params => {
            console.log("QUERY PARAMS")
            console.log(JSON.stringify(params))
            if (params['school_id']) {
                this.schoolSelect.setValue(params['school_id']);
                this.getCLASSObservations(+this.schoolSelect.value);
            }
        });

        this.getSchools();
    }

    public routeToCLASSReconciliationPage(action: string, dimensionId: number, observationId: number = null): void {

        const params = {
            action: action,
            dimension_id: dimensionId,
            school_id: +this.schoolSelect.value,
        }

        if (observationId) {
            params['observation_id'] = observationId;
        }

        this.router.navigate(['/class-observation-reconciliation'], {queryParams: params,});
    }

    public onDeleteObservation(observationId: number): void {
        console.log("onDeleteObservation: " + observationId);

        const dialogOptions: DialogOptions = {
            headerText: "Delete Reconciliation",
            bodyText: "Are you sure you want to delete this reconciliation?",
            primaryActionText: "Yes, Delete",
            cancelBtnText: "Cancel",
            btnClass: "default",
            saveChanges: false,
        };

        const dialog = this.modalService.open(
            DialogComponent,
            this.primaryModalOptions
        );
        dialog.componentInstance.dialogOptions = dialogOptions;
        dialog.componentInstance.passEntry.subscribe((choice: boolean) => {
            if (choice) {
                this.deleteObservation(observationId);
            }
        });
    }

    private getSchools(): void {
        this.caseService.getAllCases(true, 4).subscribe({
            next: (data) => {
                this.schools = data;
                console.log(data);
                this.isLoading = false;
            },
            error: (error) => {
                console.log(error);
            }
        })
    }

    private deleteObservation(observationId: number): void {
        this.classObservationsService.deleteCLASSObservation(observationId).subscribe({
            next: (data) => {
                this.utils.generateSuccessToastrMsg(
                    "The CLASS Observation Reconciliation was successfully deleted.",
                    "Reconciliation Successfully Deleted"
                );
                this.getCLASSObservations(+this.schoolSelect.value);
            },
            error: (error) => {
                console.log(error);
            }
        });
    }

    private getCLASSObservations(selectedSchoolId: number): void {

        console.log("selectedSchoolId: " + selectedSchoolId);


        this.classObservationsService.getCLASSObservations(selectedSchoolId).subscribe({
            next: (data) => {
                this.classObservations = data;
                // this.isLoading = false;
                this.schoolSelected = true;
                console.log(data);
            },
            error: (error) => {
                // this.isLoading = false;
                console.log(error);
            },
            complete: () => {
                // this.isLoading = false;
                console.log('complete');
            }
        });
    }
}
