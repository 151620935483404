<div *ngIf="isLoading" class="animated fadeIn eps-container">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-secondary text-center" role="alert">
                Verifying Campaign
            </div>
        </div>
    </div>
    <app-animated-loader></app-animated-loader>
</div>
<div *ngIf="!isLoading && wasSuccess" class="animated fadeIn eps-container">
    <div class="row mt-3">
        <div class="col">&nbsp;</div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">
                        Welcome to the IL Needs Assessment Survey
                    </h5>
                    <div *ngIf="!gotSchool">
                        <p class="card-text">Please follow the link below to go to your survey.</p>
                        <a class="card-link" href="{{ surveyUrl }}">Survey</a>
                    </div>
                    <div *ngIf="gotSchool">
                        <p class="card-text">Please confirm that {{ schoolName }} is your school.</p>
                        <a class="card-link" href="{{ schoolConformedSurveyUrl }}">Yes</a>
                        <a class="card-link" href="{{ schoolNotConfirmedSurveyUrl }}">No</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">&nbsp;</div>
    </div>
</div>
<div *ngIf="!isLoading && wasFailure" class="animated fadeIn eps-container">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">
                    Welcome to the IL Needs Assessment Survey
                </h4>
                <p>The following error occurred while trying to verify your campaign.</p>
                <p>{{ errorMessage }}</p>
            </div>
        </div>
    </div>
</div>
