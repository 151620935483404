<app-header></app-header>
<div class="eps-container">
    <div class="row text-center">
        <h4 class="mt-3">CLASS Observation Report Text Reconciliation Summary</h4>
    </div>
    <div class="row" *ngIf="!isLoading">
        <div class="col-6 mb-3">
            <label for="grade" class="form-label mt-3">Choose a school</label>
            <select class="form-select" id="grade" aria-label="Default select example"
                    [formControl]="schoolSelect">
                <option *ngFor="let s of schools" [value]="s.id">{{ s.name }} ({{ s.key }})</option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="schoolSelected">
        <div class="col-12">
            <div ngbAccordion [closeOthers]="true">
                <div ngbAccordionItem *ngFor="let dimension of classObservations">
                    <h2 ngbAccordionHeader>
                        <button ngbAccordionButton>{{ dimension.dimension_name }} ({{ dimension.observations.length }})
                        </button>
                    </h2>
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                            <ng-template>
                                <div *ngIf="dimension.observations.length < 3" class="d-flex mb-2">
                                    <button type="button" class="btn btn-primary ms-auto"
                                            (click)="routeToCLASSReconciliationPage('new', dimension.dimension_id)">
                                        <fa-icon icon="plus" class="me-1"></fa-icon>
                                        Add
                                    </button>
                                </div>
                                <div *ngFor="let observation of dimension.observations; let i = index">
                                    <div class="card mb-3">
                                        <div class="card-header">
                                            Reconciliation #{{ i + 1 }}
                                        </div>
                                        <div class="card-body">
                                            <h6 class="card-subtitle mb-2">Grade: {{ observation.grade.name }}</h6>
                                            <h6 class="card-subtitle mb-2">
                                                Indicator: {{ observation.indicator.name }}</h6>
                                            <h6 class="card-subtitle mb-2">Behavioral
                                                Marker: {{ observation.behavior_marker.name }}</h6>
                                            <h6 class="card-subtitle mb-2">Reconciliation Text</h6>
                                            <p class="card-text"
                                               [innerHTML]="observation.observation | formatParagraph"></p>
                                            <button type="button" class="btn btn-link"
                                                    (click)="routeToCLASSReconciliationPage('edit',dimension.dimension_id, observation.id)">
                                                Edit
                                            </button>
                                            <button type="button" class="btn btn-link"
                                                    (click)="onDeleteObservation(observation.id)">Delete
                                            </button>
                                        </div>
                                        <div class="card-footer">
                                            <small class="text-muted">Created by {{ observation.created_by.name }}
                                                at {{ observation.created_at + "Z" | date: 'medium' }}</small><br/>
                                            <small class="text-muted">Last update by {{ observation.updated_by.name }}
                                                at {{ observation.updated_at + "Z" | date: 'medium' }}</small>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isLoading">
        <app-animated-loader></app-animated-loader>
    </div>
</div>