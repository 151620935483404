import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ClassObservationsService} from "../../_services";
import {Utils} from "../../_helpers";
import {
    DimensionLookupAPI, GradeLookupAPI, IndicatorLookupAPI, BehaviorMarkerLookupAPI, CLASSObservationsReconciliation
} from "../../_models";
import {FormControl} from "@angular/forms";


@Component({
    selector: 'app-class-observation-reconciliation',
    templateUrl: './class-observation-reconciliation.component.html',
    styleUrls: ['./class-observation-reconciliation.component.scss']
})
export class ClassObservationReconciliationComponent implements OnInit {
    isLoading = true;
    action: string
    dimensionId: number;
    schoolId: number;
    observationId: number;
    selectedDimension: DimensionLookupAPI;
    grades: GradeLookupAPI[] = [];
    indicators: IndicatorLookupAPI[] = [];
    behavioralMarkers: BehaviorMarkerLookupAPI[] = [];
    reconciliationData: CLASSObservationsReconciliation;
    averageCategory = "middle";
    grade = new FormControl('');
    indicator = new FormControl('');
    behavioralMarker = new FormControl({value: '', disabled: true});
    observation = new FormControl({value: '', disabled: true});

    constructor(
        private utils: Utils,
        private router: Router,
        private route: ActivatedRoute,
        private classObservationService: ClassObservationsService,
    ) {
        this.indicator.valueChanges.subscribe(value => {
            console.log("indicator value changed");
            console.log(value);
            const gradeID = +this.grade.value;
            this.behavioralMarkers = this.reconciliationData.lookups.behavioral_markers.filter(behavioralMarker => behavioralMarker.indicator_id === +value && behavioralMarker.grade_id === gradeID).sort((a, b) => a.sort_order - b.sort_order);
            console.log(this.behavioralMarkers);
            if (this.behavioralMarkers.length > 0) {
                this.behavioralMarker.enable();
            }

            const t = this.reconciliationData.lookups.indicator_example_texts.find(exampleText => exampleText.indicator_id === +value && exampleText.grade_id === gradeID);
            console.log(t.middle_example_text);
            if (t) {
                if (this.averageCategory === "low") {
                    this.observation.setValue(t.low_example_text);
                } else if (this.averageCategory === "high") {
                    this.observation.setValue(t.high_example_text);
                } else {
                    this.observation.setValue(t.middle_example_text);
                }
                this.observation.enable();
            }
        })

        this.grade.valueChanges.subscribe(value => {
            console.log("grade value changed");
            console.log(value);
            const selectedGradeRecord = this.reconciliationData.dimension.survey_response_data.find(d => d.grade_key === +value);
            if (selectedGradeRecord) {
                if (selectedGradeRecord.average_rating <= 2.5) {
                    this.averageCategory = "low";
                } else if (selectedGradeRecord.average_rating > 5.5) {
                    this.averageCategory = "high";
                } else {
                    this.averageCategory = "middle";
                }
            }
        })

        this.behavioralMarker.valueChanges.subscribe(value => {
            const gradeID = +this.grade.value;
            const behavioralMarkerID = +value;

            const t = this.reconciliationData.lookups.behavioral_marker_example_texts.find(exampleText => exampleText.behavior_marker_id === behavioralMarkerID && exampleText.grade_id === gradeID);
            if (t) {
                const currentText = this.observation.value;
                if (this.averageCategory === "low") {
                    this.observation.setValue(currentText + "\n\n" + t.low_example_text);
                } else if (this.averageCategory === "high") {
                    this.observation.setValue(currentText + "\n\n" + t.high_example_text);
                } else {
                    this.observation.setValue(currentText + "\n\n" + t.middle_example_text);
                }
                this.observation.enable();
            }
        })
    }

    ngOnInit() {
        // this.getCLASSObservationReconciliationData();

        // Access query parameters
        this.route.queryParams.subscribe(params => {
            console.log("QUERY PARAMS")
            console.log(JSON.stringify(params))
            this.action = params['action'];
            this.dimensionId = +params['dimension_id'];
            this.schoolId = +params['school_id'];
            if (params['observation_id']) {
                this.observationId = +params['observation_id'];
            }
            console.log("action: " + this.action);
            console.log("dimension_id: " + this.dimensionId);
            console.log("observation_id: " + this.observationId);
            console.log("school_id: " + this.schoolId);

        });

        this.getCLASSObservationReconciliationData();
    }

    saveObservation(): void {
        console.log("saveObservation");
        const observation = {
            school_id: this.schoolId,
            grade_id: +this.grade.value,
            indicator_id: +this.indicator.value,
            behavior_marker_id: +this.behavioralMarker.value,
            observations: this.observation.value,
        }

        const params = {
            school_id: this.schoolId,
        }

        if (this.action === "edit") {
            this.classObservationService.putCLASSObservationReconciliation(this.observationId, observation).subscribe({
                next: (data) => {
                    this.utils.generateSuccessToastrMsg(data["message"], "Reconciliation saved successfully");
                    this.router.navigate(['/class-observations'], {queryParams: params,});
                },
                error: (error) => {
                    this.utils.generateErrorToastrMsg(error["message"], "Error saving reconciliation");
                    console.log(error);
                }
            });
        } else if (this.action === "new") {
            this.classObservationService.createCLASSObservations(observation).subscribe({
                next: (data) => {
                    this.utils.generateSuccessToastrMsg(data["message"], "Reconciliation saved successfully");
                    this.router.navigate(['/class-observations'], {queryParams: params,});
                },
                error: (error) => {
                    this.utils.generateErrorToastrMsg(error["message"], "Error saving reconciliation");
                    console.log(error);
                }
            });
        }
    }

    public onCancel(): void {
        const params = {
            school_id: this.schoolId,
        }
        this.router.navigate(['/class-observations'], {queryParams: params,});
    }

    public infoMessage(): string {

        let message = "";

        if (this.selectedDimension.class_observation_count === 1) {
            message = "1 reconciliation has been saved ";
        } else {
            message = `${this.selectedDimension.class_observation_count} reconciliations have been saved `;
        }

        message += `for this dimension. Only three reconciliations per dimension can be saved.`;

        return message;
    }

    private getCLASSObservationReconciliationData(): void {
        this.classObservationService.getCLASSObservationReconciliation(this.schoolId, this.dimensionId, this.observationId).subscribe({
            next: (data) => {
                this.reconciliationData = data;
                // this.gotDimension = true;
                console.log(data);
                console.log(this.dimensionId);
                this.selectedDimension = this.reconciliationData.dimension;
                this.grades = this.reconciliationData.lookups.grades.sort((a, b) => a.sort_order - b.sort_order);
                this.indicators = this.reconciliationData.lookups.indicators.filter(indicator => indicator.dimension_id === this.dimensionId).sort((a, b) => a.sort_order - b.sort_order);
                console.log(this.selectedDimension);

                if (this.action === "edit") {
                    this.behavioralMarkers = this.reconciliationData.lookups.behavioral_markers.filter(behavioralMarker => behavioralMarker.indicator_id === this.reconciliationData.reconciliation.indicator_id && behavioralMarker.grade_id === this.reconciliationData.reconciliation.grade_id).sort((a, b) => a.sort_order - b.sort_order);
                    console.log(this.behavioralMarkers);
                    if (this.behavioralMarkers.length > 0) {
                        this.behavioralMarker.enable();
                    }

                    this.grade.setValue(this.reconciliationData.reconciliation.grade_id.toString());
                    this.indicator.setValue(this.reconciliationData.reconciliation.indicator_id.toString());
                    this.behavioralMarker.setValue(this.reconciliationData.reconciliation.behavioral_marker_id.toString())
                    this.observation.setValue(this.reconciliationData.reconciliation.reconciliation_text);
                    this.observation.enable();
                }

                this.isLoading = false;
            },
            error: (error) => {
                // this.gotDimension = false;
                console.log(error);
            }
        });
    }


}
