import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {
    AuthenticationService,
    CaseService,
    SurveyService,
} from "../../_services";
import {ActivityAPI, Survey} from "../../_models";
import {Location} from "@angular/common";
import {ActivityService} from "app/_services/activity.service";

@Component({
    selector: "app-view-survey",
    templateUrl: "./view-survey.component.html",
    styleUrls: ["./view-survey.component.scss"],
    providers: [SurveyService],
})
export class ViewSurveyComponent implements OnInit {
    surveyId: number | null = null;
    title: string | null = null;
    json: object = {};
    response: object = {};
    setSchoolAndDistrictNameReadOnly = false;
    isSurveyLoaded = false;
    sub: any;
    sourceType = "Standalone";
    showBreadcrumb = false;
    latitude: number | null = null;
    longitude: number | null = null;
    sourceId: number | null = null;
    case: any;
    activity: ActivityAPI | null = null;
    currUser = this.authService.currentUserValue;
    isCurrentUserAnonymous = this.currUser.username === "anonymous";

    constructor(
        private route: ActivatedRoute,
        private surveyService: SurveyService,
        private caseService: CaseService,
        private activityService: ActivityService,
        private _location: Location,
        private router: Router,
        private authService: AuthenticationService
    ) {
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe((params) => {
            console.log(
                `[ViewSurveyComponent.ngOnInit()] params = ${JSON.stringify(params)}`
            );
            this.surveyId = params["id"];
            this.getSurveyData();
        });

        let sourceId = this.route.parent?.snapshot.paramMap.get("id");
        this.sourceId = sourceId ? +sourceId : null;
        console.log(`[ViewSurveyComponent.ngOnInit()] parentID = ${this.sourceId}`);

        console.log(
            `[ViewSurveyComponent.ngOnInit()] responseUrl = ${this.router.url}`
        );
        const responseUrlParts = this.router.url.split("/");
        console.log(
            `[ViewSurveyComponent.ngOnInit()] responseUrlParts = ${responseUrlParts}`
        );
        const doesTypeExist = this.router.url.includes("type");
        const doesIdExist = this.router.url.includes("id");
        console.log(
            `[ViewSurveyComponent.ngOnInit()] doesTypeExist = ${doesTypeExist}`
        );
        const whichResponseUrl = responseUrlParts[1];
        if (whichResponseUrl === "cases") {
            this.sourceType = "Case";
            this.getCase(+responseUrlParts[2]);
        } else if (whichResponseUrl === "activities") {
            this.sourceType = "Activity";
            this.getActivity(+responseUrlParts[2]);
        } else if (doesTypeExist && doesIdExist) {
            const typeIndex = this.router.url.indexOf("type");
            const typePart = this.router.url.substr(typeIndex);
            const typeParts = typePart.split("&");
            const typeParts2 = typeParts[0].split("=");
            const idParts = typeParts[1].split("=");
            const queryParams = this.route.snapshot.queryParams;
            const rcdts = queryParams["rcdts"];
            const wasSchoolConfirmed = queryParams["school_confirmed"];
            // console.log(`[ViewSurveyComponent.ngOnInit()] type = ${typeParts2[1]}`);
            // console.log(`[ViewSurveyComponent.ngOnInit()] id = ${idParts[1]}`);
            // console.log(`[ViewSurveyComponent.ngOnInit()] rcdts = ${rcdts}`);
            // console.log(`[ViewSurveyComponent.ngOnInit()] wasSchoolConfirmed = ${wasSchoolConfirmed}`);
            if (wasSchoolConfirmed === "true") {
                this.response = {
                    "School and District Name": rcdts,
                    "RCDTS": rcdts,
                };
                this.setSchoolAndDistrictNameReadOnly = true;
            } else {
                this.response = {};
            }
            this.sourceType = "Campaign";
            this.sourceId = +idParts[1];
        } else {
            this.sourceType = "Standalone";
            this.showBreadcrumb = true;
        }

        this.getLocation();
    }

    public setSourceType(): string {
        return this.router.url.indexOf("cases") !== -1 ? "Case" : "Standalone";
    }

    public getSurveyData() {
        if (this.surveyId === null) return;
        this.surveyService.getSurvey(this.surveyId).subscribe((data) => {
            this.title = data.name;
            let survey_structure = data["structure"];
            if (this.setSchoolAndDistrictNameReadOnly) {
                if ("pages" in survey_structure) {
                    // @ts-ignore
                    let page1 = survey_structure["pages"].filter(page => {
                        return page["name"] === "page1"
                    })[0];
                    if (page1 && "elements" in page1) {
                        let schoolAndDistrictNameQuestion = page1["elements"].filter(element => {
                            return element["name"] === "School and District Name";
                        })[0];
                        schoolAndDistrictNameQuestion["readOnly"] = true;
                    }
                }
            }
            console.log(`${JSON.stringify(survey_structure)}`);
            this.json = survey_structure;
            this.isSurveyLoaded = true;
        });
    }

    public back(): void {
        this._location.back();
    }

    public sendData(_survey: Survey) {
        const surveyData = {
            survey_id: this.surveyId,
            structure: _survey,
            case_id: this.sourceType === "Case" ? this.sourceId : null,
            activity_id: this.sourceType === "Activity" ? this.sourceId : null,
            campaign_id: this.sourceType === "Campaign" ? this.sourceId : null,
            source_type: this.sourceType,
            latitude: this.latitude,
            longitude: this.longitude,
        };

        this.surveyService.saveSurveyResponse(surveyData).subscribe((data) => {
            localStorage.removeItem("surveyResponseData");
            if (!this.isCurrentUserAnonymous) {
                setTimeout(() => {
                    // this._location.back();
                    if (this.sourceType === "Case") {
                        this.router.navigate(["/cases", this.case.id]);
                    } else if (this.sourceType === "Activity") {
                        this.router.navigate([
                            "/activities",
                            this.activity ? this.activity.id : "",
                        ]);
                    } else {
                        this.router.navigate(["/forms"]);
                    }
                }, 2500);
            } else {
                this.authService.logout();
            }
        });
    }

    private getActivity(activityID: number): void {
        this.activityService.getActivity(activityID).subscribe(
            (data) => {
                this.activity = data;
                this.showBreadcrumb = true;
            },
            (error) => {
                console.log(
                    `[ViewSurveyComponent] error getting activity: ${error.message}`
                );
            }
        );
    }

    private getCase(caseID: number): void {
        console.log(`[ViewSurveyComponent] getting case: case id = ${caseID}`);

        this.caseService.getCase(caseID).subscribe(
            (data) => {
                this.case = data;
                this.showBreadcrumb = true;
            },
            (error) => {
                console.log(
                    `[ViewSurveyComponent] error getting case: ${error.message}`
                );
            }
        );
    }

    private getLocation(): void {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.latitude = position.coords.latitude;
                    this.longitude = position.coords.longitude;
                },
                (err) => {
                    this.latitude = null;
                    this.longitude = null;
                    console.log(`ERROR(${err.code}): ${err.message}`);
                }
            );
        } else {
            this.latitude = null;
            this.longitude = null;
            console.log("No support for geolocation");
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
